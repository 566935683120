import React from 'react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';

interface ServiceCategoryProps {
  icon: LucideIcon;
  title: string;
  description: string;
  features: string[] | undefined;
  benefits: string[] | undefined;
  index: number;
}

export const ServiceCategory: React.FC<ServiceCategoryProps> = ({
  icon: Icon,
  title,
  description,
  features = [],
  benefits = [],
  index,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.2 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className="bg-white/5 rounded-lg backdrop-blur-sm hover:bg-white/10 transition-all duration-500 group relative overflow-hidden"
    >
      <div className="p-8 border-b border-white/10 relative">
        <motion.div
          animate={{
            rotate: isHovered ? 360 : 0,
            scale: isHovered ? 1.1 : 1
          }}
          transition={{ duration: 0.5 }}
          className="mb-6"
        >
          <Icon className="w-12 h-12 text-[#0ED2F7]" />
        </motion.div>
        <h3 className="text-2xl font-semibold mb-4 group-hover:text-[#0ED2F7] transition-colors">
          {title}
        </h3>
        <p className="text-gray-400 group-hover:text-white transition-colors">
          {description}
        </p>
      </div>

      <div className="p-8 grid md:grid-cols-2 gap-8 relative">
        {features && features.length > 0 && (
          <div>
            <h4 className="text-lg font-semibold mb-4 text-[#0ED2F7]">
              Key Features
            </h4>
            <ul className="space-y-3">
              {features.map((feature, idx) => (
                <li
                  key={idx}
                  className="flex items-center text-sm text-gray-300 group-hover:text-white transition-colors"
                >
                  <span className="w-1.5 h-1.5 bg-[#0ED2F7] rounded-full mr-2" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        )}

        {benefits && benefits.length > 0 && (
          <div>
            <h4 className="text-lg font-semibold mb-4 text-[#0ED2F7]">
              Benefits
            </h4>
            <ul className="space-y-3">
              {benefits.map((benefit, idx) => (
                <li
                  key={idx}
                  className="flex items-center text-sm text-gray-300 group-hover:text-white transition-colors"
                >
                  <span className="w-1.5 h-1.5 bg-[#0ED2F7] rounded-full mr-2" />
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </motion.div>
  );
};