import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Zap, Clock, Cpu, Globe } from 'lucide-react';

export const FutureSection: React.FC = () => {
  const { t } = useTranslation();
  const points = t('about.future.points', { returnObjects: true }) as string[];
  
  if (!Array.isArray(points)) {
    console.error('Future points is not an array:', points);
    return null;
  }

  const icons = [Cpu, Clock, Globe, Zap];

  return (
    <section className="py-20 relative overflow-hidden">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 0.1 }}
        viewport={{ once: true }}
        className="absolute inset-0 bg-gradient-radial from-[#0ED2F7] to-transparent"
      />
      
      <div className="max-w-6xl mx-auto px-4 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold mb-6">{t('about.future.title')}</h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            {t('about.future.description')}
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {points.map((point, index) => {
            const Icon = icons[index];
            return (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ scale: 1.05 }}
                className="bg-white/5 p-6 rounded-lg backdrop-blur-sm"
              >
                <div className="mb-4">
                  <Icon className="w-8 h-8 text-[#0ED2F7]" />
                </div>
                <p className="text-lg font-semibold">{point}</p>
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  );
};