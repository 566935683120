import { useEffect, useCallback } from 'react';

interface PerformanceMetrics {
  FCP: number;
  LCP: number;
  FID: number;
  CLS: number;
}

export const usePerformanceMonitoring = (callback?: (metrics: PerformanceMetrics) => void) => {
  const reportWebVitals = useCallback((metric: any) => {
    // Log to console in development
    if (process.env.NODE_ENV === 'development') {
      console.log(metric);
    }

    // Send to analytics in production
    if (process.env.NODE_ENV === 'production') {
      const body = {
        name: metric.name,
        value: metric.value,
        id: metric.id,
        startTime: metric.startTime,
        duration: metric.duration
      };

      // Example: Send to analytics endpoint
      // fetch('/api/analytics/vitals', {
      //   method: 'POST',
      //   body: JSON.stringify(body)
      // });
    }

    // Custom callback
    if (callback) {
      callback({
        FCP: metric.name === 'FCP' ? metric.value : 0,
        LCP: metric.name === 'LCP' ? metric.value : 0,
        FID: metric.name === 'FID' ? metric.value : 0,
        CLS: metric.name === 'CLS' ? metric.value : 0
      });
    }
  }, [callback]);

  useEffect(() => {
    // First Contentful Paint
    const fcpObserver = new PerformanceObserver((list) => {
      const entries = list.getEntries();
      entries.forEach((entry) => {
        reportWebVitals({
          name: 'FCP',
          value: entry.startTime,
          id: entry.id
        });
      });
    });

    // Largest Contentful Paint
    const lcpObserver = new PerformanceObserver((list) => {
      const entries = list.getEntries();
      entries.forEach((entry) => {
        reportWebVitals({
          name: 'LCP',
          value: entry.startTime,
          id: entry.id
        });
      });
    });

    // First Input Delay
    const fidObserver = new PerformanceObserver((list) => {
      const entries = list.getEntries();
      entries.forEach((entry) => {
        reportWebVitals({
          name: 'FID',
          value: entry.processingStart - entry.startTime,
          id: entry.id
        });
      });
    });

    // Cumulative Layout Shift
    const clsObserver = new PerformanceObserver((list) => {
      const entries = list.getEntries();
      entries.forEach((entry: any) => {
        reportWebVitals({
          name: 'CLS',
          value: entry.value,
          id: entry.id
        });
      });
    });

    try {
      fcpObserver.observe({ type: 'paint', buffered: true });
      lcpObserver.observe({ type: 'largest-contentful-paint', buffered: true });
      fidObserver.observe({ type: 'first-input', buffered: true });
      clsObserver.observe({ type: 'layout-shift', buffered: true });
    } catch (e) {
      console.error('Performance monitoring failed:', e);
    }

    return () => {
      fcpObserver.disconnect();
      lcpObserver.disconnect();
      fidObserver.disconnect();
      clsObserver.disconnect();
    };
  }, [reportWebVitals]);
};