import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  ChevronRight, 
  Code, 
  Palette, 
  Laptop, 
  Clock, 
  MapPin,
  Gift,
  Coffee,
  Gamepad,
  Brain,
  Music,
  Box,
  Megaphone
} from 'lucide-react';

export const Careers: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const positions = [
    {
      title: t('careers.positions.list.unity.title'),
      department: "Game Development",
      location: t('careers.positions.list.unity.location'),
      type: t('careers.positions.list.unity.type'),
      icon: Gamepad,
      requirements: ["Unity3D", "C#", "Game Physics", "Mobile Game Development"],
      isNew: true,
      forStudents: true,
      id: "unity-dev"
    },
    {
      title: t('careers.positions.list.artist3d.title'),
      department: "Game Development",
      location: t('careers.positions.list.artist3d.location'),
      type: t('careers.positions.list.artist3d.type'),
      icon: Box,
      requirements: ["Blender", "Maya", "Character Design", "Animation"],
      isNew: true,
      forStudents: true,
      id: "3d-artist"
    },
    {
      title: t('careers.positions.list.sound.title'),
      department: "Game Development",
      location: t('careers.positions.list.sound.location'),
      type: t('careers.positions.list.sound.type'),
      icon: Music,
      requirements: ["Sound Design", "Audio Engineering", "Game Audio", "FMOD"],
      isNew: true,
      forStudents: true,
      id: "sound-designer"
    },
    {
      title: t('careers.positions.list.backend.title'),
      department: "Engineering",
      location: t('careers.positions.list.backend.location'),
      type: t('careers.positions.list.backend.type'),
      icon: Code,
      requirements: ["C# & .NET Core", "Microservices", "Azure", "SQL Server"],
      isNew: true,
      forStudents: true,
      id: "backend-dev"
    },
    {
      title: t('careers.positions.list.frontend.title'),
      department: "Engineering",
      location: t('careers.positions.list.frontend.location'),
      type: t('careers.positions.list.frontend.type'),
      icon: Code,
      requirements: ["React", "TypeScript", "Next.js", "TailwindCSS"],
      isNew: true,
      forStudents: true,
      id: "frontend-dev"
    },
    {
      title: t('careers.positions.list.marketing.title'),
      department: "Marketing",
      location: t('careers.positions.list.marketing.location'),
      type: t('careers.positions.list.marketing.type'),
      icon: Megaphone,
      requirements: ["Social Media", "Content Creation", "SEO", "Analytics"],
      isNew: true,
      forStudents: true,
      id: "digital-marketing"
    },
    {
      title: t('careers.positions.list.designer.title'),
      department: "Design",
      location: t('careers.positions.list.designer.location'),
      type: t('careers.positions.list.designer.type'),
      icon: Palette,
      requirements: ["Figma", "User Research", "Prototyping", "Design Systems"],
      isNew: true,
      forStudents: true,
      id: "ux-designer"
    }
  ];

  const perks = [
    {
      icon: Laptop,
      title: t('careers.perks.items.macbook.title'),
      description: t('careers.perks.items.macbook.description')
    },
    {
      icon: MapPin,
      title: t('careers.perks.items.coworking.title'),
      description: t('careers.perks.items.coworking.description')
    },
    {
      icon: Clock,
      title: t('careers.perks.items.flexible.title'),
      description: t('careers.perks.items.flexible.description')
    },
    {
      icon: Brain,
      title: t('careers.perks.items.student.title'),
      description: t('careers.perks.items.student.description')
    },
    {
      icon: Gift,
      title: t('careers.perks.items.welcome.title'),
      description: t('careers.perks.items.welcome.description')
    },
    {
      icon: Coffee,
      title: t('careers.perks.items.balance.title'),
      description: t('careers.perks.items.balance.description')
    }
  ];

  const handleViewOpportunities = () => {
    const positionsSection = document.getElementById('positions');
    if (positionsSection) {
      positionsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleApply = (positionId: string) => {
    navigate('/contact', { 
      state: { 
        position: positionId,
        subject: `Application for ${positions.find(p => p.id === positionId)?.title}`
      }
    });
  };

  return (
    <div className="min-h-screen py-20">
      {/* Hero Section */}
      <div className="relative overflow-hidden mb-20">
        <div className="absolute inset-0">
          <motion.div
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{ duration: 20, repeat: Infinity, repeatType: "reverse" }}
          >
            <img
              src="https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?auto=format&fit=crop&q=80"
              alt="Students Collaborating"
              className="w-full h-full object-cover opacity-20"
            />
          </motion.div>
          <div className="absolute inset-0 bg-gradient-to-b from-[#1F1F1F] via-transparent to-[#1F1F1F]" />
        </div>

        <div className="container mx-auto px-4 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center max-w-4xl mx-auto py-20"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-[#0ED2F7] to-white">
              {t('careers.hero.title')}
            </h1>
            <p className="text-xl text-gray-300 mb-4">
              {t('careers.hero.subtitle')}
            </p>
            <p className="text-lg text-gray-400 mb-8">
              {t('careers.hero.description')}
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleViewOpportunities}
              className="bg-[#0ED2F7] text-white px-8 py-3 rounded-lg font-semibold inline-flex items-center gap-2 hover:bg-opacity-90 transition-all"
            >
              {t('careers.hero.cta')}
              <ChevronRight size={20} />
            </motion.button>
          </motion.div>
        </div>
      </div>

      {/* Perks Section */}
      <div className="container mx-auto px-4 mb-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center max-w-3xl mx-auto mb-12"
        >
          <h2 className="text-3xl font-bold mb-4">{t('careers.perks.title')}</h2>
          <p className="text-gray-400">{t('careers.perks.subtitle')}</p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="grid md:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          {perks.map((perk, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-white/5 p-6 rounded-lg backdrop-blur-sm hover:bg-white/10 transition-all group"
            >
              <perk.icon className="w-12 h-12 text-[#0ED2F7] mb-4 group-hover:scale-110 transition-transform" />
              <h3 className="text-xl font-semibold mb-2">{perk.title}</h3>
              <p className="text-gray-400">{perk.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* Open Positions */}
      <div id="positions" className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center max-w-3xl mx-auto mb-12"
        >
          <h2 className="text-3xl font-bold mb-4">{t('careers.positions.title')}</h2>
          <p className="text-gray-400">{t('careers.positions.subtitle')}</p>
        </motion.div>

        <div className="max-w-4xl mx-auto">
          {positions.map((position, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              onClick={() => handleApply(position.id)}
              className="bg-white/5 rounded-lg p-6 mb-4 hover:bg-white/10 transition-all cursor-pointer group relative overflow-hidden"
            >
              {position.isNew && (
                <div className="absolute top-0 right-0">
                  <div className="bg-[#0ED2F7] text-white text-sm px-3 py-1 rounded-bl-lg">
                    New
                  </div>
                </div>
              )}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <position.icon className="w-8 h-8 text-[#0ED2F7]" />
                  <div>
                    <h3 className="text-xl font-semibold group-hover:text-[#0ED2F7] transition-colors">
                      {position.title}
                    </h3>
                    <p className="text-gray-400">
                      {position.department} · {position.location} · {position.type}
                    </p>
                    <div className="flex flex-wrap gap-2 mt-2">
                      {position.requirements.map((req, idx) => (
                        <span
                          key={idx}
                          className="text-xs bg-white/10 text-gray-300 px-2 py-1 rounded"
                        >
                          {req}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <ChevronRight className="transform group-hover:translate-x-2 transition-transform" />
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mt-12"
        >
          <h3 className="text-2xl font-bold mb-4">{t('careers.apply.title')}</h3>
          <p className="text-gray-400 mb-6">{t('careers.apply.description')}</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/contact')}
            className="bg-[#0ED2F7] text-white px-8 py-3 rounded-lg font-semibold inline-flex items-center gap-2 hover:bg-opacity-90 transition-all"
          >
            {t('careers.apply.button')}
            <ChevronRight size={20} />
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
};