import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Clock, Zap, Brain } from 'lucide-react';

export const VisionSection: React.FC = () => {
  const { t } = useTranslation();

  const visionPoints = [
    {
      icon: <Clock className="w-8 h-8 text-[#0ED2F7]" />,
      title: t('about.vision.time_title'),
      description: t('about.vision.time_description')
    },
    {
      icon: <Brain className="w-8 h-8 text-[#0ED2F7]" />,
      title: t('about.vision.ai_title'),
      description: t('about.vision.ai_description')
    },
    {
      icon: <Zap className="w-8 h-8 text-[#0ED2F7]" />,
      title: t('about.vision.innovation_title'),
      description: t('about.vision.innovation_description')
    }
  ];

  return (
    <section className="py-16">
      <div className="max-w-6xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="grid md:grid-cols-3 gap-8"
        >
          {visionPoints.map((point, index) => (
            <div
              key={index}
              className="bg-white/5 p-8 rounded-xl backdrop-blur-sm hover:bg-white/10 transition-all duration-300"
            >
              <div className="mb-4">{point.icon}</div>
              <h3 className="text-xl font-semibold mb-3">{point.title}</h3>
              <p className="text-gray-400">{point.description}</p>
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};