import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { 
  Brain, 
  Globe, 
  Cloud, 
  Gamepad, 
  CreditCard, 
  ShoppingCart 
} from 'lucide-react';
import { ServiceCategory } from '../components/services/ServiceCategory';
import { ServiceHero } from '../components/services/ServiceHero';
import { ProcessTimeline } from '../components/services/ProcessTimeline';

interface ServiceData {
  title: string;
  description: string;
  features: string[];
  benefits: string[];
}

export const Services: React.FC = () => {
  const { t } = useTranslation();

  const categories = [
    {
      icon: Brain,
      key: 'ai_ml',
      index: 0
    },
    {
      icon: Globe,
      key: 'web_dev',
      index: 1
    },
    {
      icon: Cloud,
      key: 'cloud',
      index: 2
    },
    {
      icon: Gamepad,
      key: 'game_dev',
      index: 3
    },
    {
      icon: CreditCard,
      key: 'payment',
      index: 4
    },
    {
      icon: ShoppingCart,
      key: 'ecommerce',
      index: 5
    }
  ];

  const getServiceData = (key: string): ServiceData => {
    const data = t(`services.categories.${key}`, { returnObjects: true }) as ServiceData;
    return {
      title: data?.title || '',
      description: data?.description || '',
      features: Array.isArray(data?.features) ? data.features : [],
      benefits: Array.isArray(data?.benefits) ? data.benefits : []
    };
  };

  return (
    <div className="min-h-screen">
      <ServiceHero />
      
      <div className="container mx-auto px-4 py-16">
        <div className="grid gap-8">
          {categories.map((category) => {
            const serviceData = getServiceData(category.key);
            return (
              <ServiceCategory
                key={category.key}
                icon={category.icon}
                title={serviceData.title}
                description={serviceData.description}
                features={serviceData.features}
                benefits={serviceData.benefits}
                index={category.index}
              />
            );
          })}
        </div>

        <ProcessTimeline />

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center py-16"
        >
          <h2 className="text-3xl font-bold mb-4">{t('services.cta.title')}</h2>
          <p className="text-gray-400 mb-8 max-w-2xl mx-auto">{t('services.cta.description')}</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-[#0ED2F7] text-white px-8 py-3 rounded-lg font-semibold hover:bg-opacity-90 transition-all"
          >
            {t('services.cta.button')}
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
};