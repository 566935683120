import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { Header } from './Header';
import { AnimatedWrapper } from '../AnimatedWrapper';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-[#1F1F1F] text-white">
      <Header />
      <AnimatePresence mode="wait">
        <AnimatedWrapper className="pt-16">
          {children}
        </AnimatedWrapper>
      </AnimatePresence>
    </div>
  );
};