import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Clock } from 'lucide-react';

interface TimelineEvent {
  year: string;
  title: string;
  description: string;
}

export const TimelineSection: React.FC = () => {
  const { t } = useTranslation();
  const events = t('about.timeline.events', { returnObjects: true }) as TimelineEvent[];

  if (!Array.isArray(events)) {
    console.error('Timeline events is not an array:', events);
    return null;
  }

  return (
    <section className="py-16">
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="text-3xl font-bold text-center mb-16"
      >
        {t('about.timeline.title')}
      </motion.h2>

      <div className="max-w-4xl mx-auto relative">
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-px bg-gradient-to-b from-[#0ED2F7] to-transparent" />

        {events.map((event, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            className={`flex items-center mb-16 ${
              index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'
            }`}
          >
            <div className={`w-1/2 ${index % 2 === 0 ? 'pr-8 text-right' : 'pl-8'}`}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="bg-white/5 p-6 rounded-lg backdrop-blur-sm"
              >
                <div className="flex items-center justify-center mb-4">
                  <Clock className="w-6 h-6 text-[#0ED2F7]" />
                </div>
                <h3 className="text-xl font-semibold mb-2">{event.title}</h3>
                <p className="text-gray-400 text-sm">{event.description}</p>
              </motion.div>
            </div>

            <div className="relative">
              <motion.div
                whileHover={{ scale: 1.2 }}
                className="w-12 h-12 rounded-full bg-[#0ED2F7] flex items-center justify-center font-bold text-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                {event.year}
              </motion.div>
            </div>

            <div className="w-1/2" />
          </motion.div>
        ))}
      </div>
    </section>
  );
};