import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'lucide-react';

export const ServiceHero: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="relative h-[60vh] flex items-center justify-center mb-16 overflow-hidden">
      <div className="absolute inset-0 z-0">
        <motion.div
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
          className="w-full h-full"
        >
          <img
            src="https://images.unsplash.com/photo-1518432031352-d6fc5c10da5a?auto=format&fit=crop&q=80"
            alt="Services Background"
            className="w-full h-full object-cover opacity-30"
          />
        </motion.div>
        <div className="absolute inset-0 bg-gradient-to-b from-[#1F1F1F]/50 via-[#1F1F1F]/70 to-[#1F1F1F]" />
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="relative z-10 text-center px-4"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="w-20 h-20 mx-auto mb-8 rounded-full bg-[#0ED2F7]/20 flex items-center justify-center"
        >
          <div className="w-16 h-16 rounded-full bg-[#0ED2F7]/40 flex items-center justify-center">
            <div className="w-12 h-12 rounded-full bg-[#0ED2F7] flex items-center justify-center">
              <motion.div
                animate={{ 
                  scale: [1, 1.2, 1],
                  rotate: [0, 360]
                }}
                transition={{ 
                  duration: 4,
                  repeat: Infinity,
                  ease: "linear"
                }}
              >
                <img src="/vite.svg" alt="Service Icon" className="w-8 h-8" />
              </motion.div>
            </div>
          </div>
        </motion.div>

        <h1 className="text-4xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-[#0ED2F7] to-white">
          {t('services.title')}
        </h1>
        <p className="text-xl text-gray-300 max-w-2xl mx-auto mb-12">
          {t('services.subtitle')}
        </p>

        <motion.div
          animate={{ y: [0, 10, 0] }}
          transition={{ duration: 2, repeat: Infinity }}
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
        >
          <ChevronDown className="w-8 h-8 text-[#0ED2F7]" />
        </motion.div>
      </motion.div>
    </div>
  );
};