import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Mail, MapPin, Clock, ArrowRight, Github, Linkedin, Twitter } from 'lucide-react';
import { useLocation } from 'react-router-dom';

export const Contact: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { position, subject } = location.state || {};

  const contactInfo = [
    {
      icon: Mail,
      title: "Email Us",
      description: "hello@chronex.ai",
      link: `mailto:hello@chronex.ai${subject ? `?subject=${encodeURIComponent(subject)}` : ''}`
    },
    {
      icon: MapPin,
      title: "Visit Our Office",
      description: "Phuket, Thailand",
      link: "https://maps.google.com"
    },
    {
      icon: Clock,
      title: "Working Hours",
      description: "24/7 - Student Friendly",
      link: null
    }
  ];

  const socialLinks = [
    { icon: Github, href: "#", label: "GitHub" },
    { icon: Linkedin, href: "#", label: "LinkedIn" },
    { icon: Twitter, href: "#", label: "Twitter" }
  ];

  return (
    <div className="min-h-screen py-20">
      {/* Hero Section */}
      <div className="relative overflow-hidden mb-20">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          transition={{ duration: 1 }}
          className="absolute inset-0"
        >
          <img
            src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&q=80"
            alt="Contact Background"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-[#1F1F1F] via-transparent to-[#1F1F1F]" />
        </motion.div>

        <div className="container mx-auto px-4 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center max-w-3xl mx-auto py-20"
          >
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="w-20 h-20 mx-auto mb-8 rounded-full bg-[#0ED2F7]/20 flex items-center justify-center"
            >
              <div className="w-16 h-16 rounded-full bg-[#0ED2F7]/40 flex items-center justify-center">
                <div className="w-12 h-12 rounded-full bg-[#0ED2F7] flex items-center justify-center">
                  <motion.div
                    animate={{ 
                      rotate: [0, 360],
                      scale: [1, 1.2, 1]
                    }}
                    transition={{ 
                      duration: 4,
                      repeat: Infinity,
                      ease: "linear"
                    }}
                  >
                    <Mail className="w-6 h-6 text-white" />
                  </motion.div>
                </div>
              </div>
            </motion.div>

            <h1 className="text-4xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-[#0ED2F7] to-white">
              {position ? `Apply for ${position}` : t('contact.title')}
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              {position ? "We're excited to hear from you! Send us your application and let's shape the future together." : t('contact.subtitle')}
            </p>
          </motion.div>
        </div>
      </div>

      {/* Contact Cards */}
      <div className="container mx-auto px-4 mb-20">
        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {contactInfo.map((info, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
              className="bg-white/5 p-8 rounded-lg backdrop-blur-sm group"
            >
              <div className="mb-6">
                <info.icon className="w-10 h-10 text-[#0ED2F7] group-hover:scale-110 transition-transform" />
              </div>
              <h3 className="text-xl font-semibold mb-2">{info.title}</h3>
              {info.link ? (
                <a
                  href={info.link}
                  className="text-gray-400 hover:text-[#0ED2F7] transition-colors flex items-center gap-2 group"
                >
                  {info.description}
                  <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                </a>
              ) : (
                <p className="text-gray-400">{info.description}</p>
              )}
            </motion.div>
          ))}
        </div>
      </div>

      {/* Social Links */}
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <h2 className="text-2xl font-semibold mb-8">Connect With Us</h2>
          <div className="flex justify-center space-x-6">
            {socialLinks.map((social, index) => (
              <motion.a
                key={index}
                href={social.href}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                className="text-gray-400 hover:text-[#0ED2F7] transition-colors"
                aria-label={social.label}
              >
                <social.icon className="w-8 h-8" />
              </motion.a>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};