import React from 'react';
import { useLocation } from 'react-router-dom';

interface SEOHeadProps {
  title?: string;
  description?: string;
  image?: string;
}

export const SEOHead: React.FC<SEOHeadProps> = ({
  title = 'Chronex.ai - Shaping Tomorrow\'s Technology',
  description = 'Pioneering Time-Based AI Solutions for the future. Join us in revolutionizing how businesses interact with time through cutting-edge artificial intelligence.',
  image = 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80'
}) => {
  const location = useLocation();
  const canonicalUrl = `https://chronex.ai${location.pathname}`;

  React.useEffect(() => {
    // Update document title
    document.title = title;

    // Update meta tags
    const metaTags = {
      description,
      'og:title': title,
      'og:description': description,
      'og:image': image,
      'og:url': canonicalUrl,
      'twitter:card': 'summary_large_image',
      'twitter:title': title,
      'twitter:description': description,
      'twitter:image': image
    };

    Object.entries(metaTags).forEach(([name, content]) => {
      const existingTag = document.querySelector(`meta[property="${name}"]`) ||
                         document.querySelector(`meta[name="${name}"]`);
      
      if (existingTag) {
        existingTag.setAttribute('content', content);
      } else {
        const tag = document.createElement('meta');
        if (name.startsWith('og:')) {
          tag.setAttribute('property', name);
        } else {
          tag.setAttribute('name', name);
        }
        tag.setAttribute('content', content);
        document.head.appendChild(tag);
      }
    });

    // Update canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', canonicalUrl);
  }, [title, description, image, canonicalUrl]);

  return null;
};