import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { VisionSection } from '../components/about/VisionSection';
import { TeamSection } from '../components/about/TeamSection';
import { TimelineSection } from '../components/about/TimelineSection';
import { FutureSection } from '../components/about/FutureSection';

export const About: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen">
      <section className="relative min-h-[80vh] flex items-center justify-center overflow-hidden">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.2 }}
          transition={{ duration: 1 }}
          className="absolute inset-0 z-0"
        >
          <div className="absolute inset-0 bg-gradient-to-b from-[#1F1F1F] via-transparent to-[#1F1F1F]" />
          <video
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover"
          >
            <source src="https://assets.mixkit.co/videos/preview/mixkit-digital-animation-of-futuristic-devices-99786-large.mp4" type="video/mp4" />
          </video>
        </motion.div>

        <div className="container mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center max-w-4xl mx-auto"
          >
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="w-20 h-20 mx-auto mb-8 rounded-full bg-[#0ED2F7]/20 flex items-center justify-center"
            >
              <div className="w-16 h-16 rounded-full bg-[#0ED2F7]/40 flex items-center justify-center">
                <div className="w-12 h-12 rounded-full bg-[#0ED2F7] flex items-center justify-center">
                  <motion.div
                    animate={{ 
                      rotate: [0, 360],
                      scale: [1, 1.2, 1]
                    }}
                    transition={{ 
                      duration: 4,
                      repeat: Infinity,
                      ease: "linear"
                    }}
                  >
                    <img src="/vite.svg" alt="Chronex.ai Logo" className="w-8 h-8" />
                  </motion.div>
                </div>
              </div>
            </motion.div>

            <h1 className="text-4xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-[#0ED2F7] to-white">
              {t('about.hero.title')}
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              {t('about.hero.subtitle')}
            </p>
            <p className="text-gray-400 text-lg">
              {t('about.hero.description')}
            </p>
          </motion.div>
        </div>
      </section>

      <VisionSection />
      <TeamSection />
      <TimelineSection />
      <FutureSection />
    </div>
  );
};