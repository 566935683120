import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { pageVariants } from '../utils/animations';

interface AnimatedWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const AnimatedWrapper: React.FC<AnimatedWrapperProps> = ({ children, className = '' }) => {
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className={className}
    >
      {children}
    </motion.div>
  );
};