import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react';

export const ProcessTimeline: React.FC = () => {
  const { t } = useTranslation();
  const steps = t('services.process.steps', { returnObjects: true }) as Array<{ title: string; description: string }> || [];

  return (
    <div className="py-16">
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="text-3xl font-bold text-center mb-12"
      >
        {t('services.process.title')}
      </motion.h2>

      <div className="max-w-4xl mx-auto">
        {steps.map((step, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
            className="relative flex items-start mb-12 last:mb-0 group"
          >
            <div className="flex items-center">
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-r from-[#0ED2F7] to-[#0ED2F7]/70 text-white font-bold relative"
              >
                <motion.div
                  animate={{ scale: [1, 1.2, 1] }}
                  transition={{ duration: 2, repeat: Infinity }}
                  className="absolute inset-0 bg-[#0ED2F7] rounded-full opacity-20"
                />
                <CheckCircle className="w-6 h-6" />
              </motion.div>
              <div className="ml-6">
                <h3 className="text-xl font-semibold mb-2 group-hover:text-[#0ED2F7] transition-colors">
                  {step.title}
                </h3>
                <p className="text-gray-400">{step.description}</p>
              </div>
            </div>
            {index < steps.length - 1 && (
              <motion.div
                initial={{ height: 0 }}
                whileInView={{ height: '100%' }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="absolute left-6 top-12 w-0.5 h-16 bg-gradient-to-b from-[#0ED2F7] to-transparent"
              />
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};